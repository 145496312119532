<template>
    <div class="center-erp-config-box">
        <div class="header-tabs" v-if="scene !== 'view'">
            <!-- 场站 -->
            <el-tabs
                v-model="activeStationCode"
                @tab-click="handleClick"
            >
                <template v-for="(item, index) in siteData">
                    <el-tab-pane
                        :name="item.station_id"
                        :key="index"
                    >
                        <span slot="label">{{ item.name }}</span>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <!-- 生产线 -->
            <div class="product-code-box">
                <template v-for="(item, index) in mixstationlineData">
                    <el-button
                        plain
                        :key="item.id + index"
                        type="primary"
                        size="medium"
                        :class="index === activeLineIndex ? 'active-color' : ''"
                        @click="activeLineNameFun(item, index)"
                    >
                        {{ item.name }}
                    </el-button>
                </template>
            </div>
        </div>
        <!-- 详细内容 -->
        <div class="config-content-box">
            <div class="config-content-title">
                <div>
                    <span class="iconfont">&#xe61e;</span>
                    <p>名称: {{ activeLineName }}</p>
                </div>
                <div>
                    <span class="iconfont">&#xe675;</span>
                    <p>工控厂商: {{ activeLine.manufacturers }}</p>
                </div>
                <!-- <div>
                    <span class="iconfont">&#xe664;</span>
                    <p>生产状态: {{ activeLineName }}</p>
                </div> -->
                <div>
                    <span class="iconfont">&#xe640;</span>
                    <p>是否启用: {{ activeLine.is_enabled ? '启用' : '禁用' }}</p>
                </div>
            </div>
            <div class="content-tab">
                <table class="table-table-box" rules="none" ref="tableRef">
                    <tr class="table-tr-one">
                        <th colspan="2">
                            工控系统
                        </th>
                        <th class="zhanwei"></th>
                        <th colspan="7" style="border-right:1px solid #c1c1c3; background: #F5F2EC; color: #823802">
                            ERP系统
                        </th>
                        <th rowspan="2" style="border: none">
                            <!-- <div @click="" class="operation-list"> -->
                            <div @click="preservation" class="operation-list">
                                整体发送
                            </div>
                        </th>
                    </tr>
                    <tr class="table-tr-two">
                        <th class="blue-bg-color">
                            工控编号
                        </th>
                        <th class="blue-bg-color">
                            工控料仓类型
                        </th>
                        <td class="zhanwei"></td>
                        <th class="origin-bg-color">
                            料仓名称
                        </th>
                        <th class="origin-bg-color">
                            料仓号
                        </th>
                        <th class="origin-bg-color">
                            料仓编码
                        </th>
                        <th class="origin-bg-color">
                            子类名称
                        </th>
                        <th class="origin-bg-color">
                            规格
                        </th>
                        <th class="origin-bg-color">
                            分配比例(%)
                        </th>
                        <th class="origin-bg-color">
                            操作
                        </th>
                        <th style="border: none"></th>
                    </tr>
                    <tr
                        v-for="(item, index) in controlLiaoCang"
                        :key="index"
                        class="table-tr-content"
                    >
                        <td :class="item.plcw === plcw ? 'gongkong-content current-color' : 'gongkong-content'">
                            {{ item.plcw }}
                        </td>
                        <td :class="item.plcw === plcw ? 'gongkong-content current-color' : 'gongkong-content'">
                            {{ item.plcwname }}
                        </td>
                        <td class="zhanwei"></td>
                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <span v-if="item.stock_name">{{ item.stock_name }}</span>
                            <span class="please-select" v-else>请选择</span>
                        </td>
                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <span v-if="item.stock_no">{{ item.stock_no }}</span>
                            <span class="please-select" v-else>请选择</span>
                        </td>
                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <span v-if="item.stock_code">{{ item.stock_code }}</span>
                            <span class="please-select" v-else>请选择</span>
                        </td>

                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <span v-if="item.material_child_name">{{ item.material_child_name }}</span>
                            <span class="please-select" v-else>请选择</span>
                        </td>
                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <span v-if="item.material_name">{{ item.material_name }}</span>
                            <span class="please-select" v-else>请选择</span>
                        </td>

                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <el-input
                                min="0"
                                max="100"
                                type="number"
                                placeholder="请输入"
                                style="width:96%;"
                                @mousewheel.native.prevent
                                v-model="item.distribution_ratio"
                                @change="distributionRatio(item, index)"
                            ></el-input>
                        </td>
                        <td :class="item.plcw === plcw ? 'erp-content current-color' : 'erp-content'">
                            <b v-if="item.stock_no" @click="adjustBtn(item, index)">
                                调整
                            </b>
                            <b class="xuanze" v-else @click="adjustBtn(item, index)">
                                选择
                            </b>
                        </td>
                        <td style="border:none;">
                            <button
                                style="margin-left: .2rem;"
                                class="to-be-sent-locking status-btn"
                                v-if="industrySiloData &&
                                    industrySiloData.status === 2 &&
                                    producStatus[index] &&
                                    producStatus[index].status === '1'"
                            >
                                已确认
                            </button>
                            <button
                                style="margin-left: .2rem;"
                                v-else-if="producStatus[index] && producStatus[index].status === '1'"
                                class="confirm-ing status-btn"
                            >
                                确认中
                            </button>
                            <button
                                style="margin-left: .2rem;"
                                v-else-if="producStatus[index] && producStatus[index].status === '3'"
                                class="confirm status-btn"
                            >
                                待发送
                            </button>
                            <button
                                style="margin-left: .2rem;"
                                v-else-if="producStatus[index] && producStatus[index].status === '2'"
                                class="to-be-sent status-btn"
                            >
                                待选择
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// import _ from 'loadsh';
export default {
    data() {
        return {
            activeStationCode: '', // 目前选中的场站code
            tabIndex: 0, // 顶部导航选中
            activeLine: {}, // 选中生产线数据
            activeLineIndex: 0, // 生产线信息下标
            activeLineName: '', // 选中生产线名称
            siteData: {}, // 获取场站信息对象
            mixstationlineData: [], // 获取生产线数据
            controlLiaoCang: [], // 获取工控生产线料仓列表
            controlLiaoCangOne: [],
            clickTiaozheng: {}, // 回调弹窗数据
            adjustBtnIdx: 0, // 选择的下标
            getProductsiloData: [], // 获取erp料仓列表
            baocunSunccess: '', // 保存成功编码
            producStatus: [],
            industrySiloData: {},
            plcw: '',
            activeLineNo: '',
            scene: 'page',
        };
    },
    props: {
        extr: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    mounted() {
        this.extr = this.extr || {};
        this.scene = this.extr.scene ? this.extr.scene : 'page';
        if (this.scene === 'view') {
            this.activeStationCode = this.extr.mixstation_code;
            this.activeLineNo = this.extr.line_code;
            this.plcw = this.extr.plcw;
            this.getLineList();
        } else {
            this.gitStationList();
        }
    },
    methods: {
        setProducStatus() {
            this.producStatus = this.controlLiaoCang.map(item => {
                let newStatus = {};
                if (item.plcw && item.stock_name) {
                    newStatus = {
                        status: '1',
                    };
                }
                if (item.plcw && !item.stock_name) {
                    newStatus = {
                        status: '2',
                    };
                }
                return newStatus;
            });
        },
        // 点击调整按钮
        adjustBtn(item, index) {
            if (this.scene !== 'view') {
                this.adjustBtnIdx = index;
                const queryStr = 'mixstation_code=' + this.activeStationCode + '&line_code=' + this.activeLineNo;
                this.$toast(
                    {
                        title: true,
                        text: '选择料仓',
                        type: 'eject',
                        t_url: 'tableList/index',
                        extr: {
                            code: {
                                TableCode: 'gklcxz',
                                QueryString: queryStr,
                                selectedCallback: this.productNumCallback,
                            },

                        },
                    }
                );
            }
        },
        // 弹窗数据回调
        productNumCallback(data) {
            if (Object.keys(data).length === 0) {
                const currentDate = JSON.parse(JSON.stringify(this.controlLiaoCang[this.adjustBtnIdx]));
                currentDate.stock_code = '';
                currentDate.stock_name = '';
                currentDate.stock_alias = '';
                currentDate.stock_category = '';
                currentDate.stock_category_dictcode = '';
                currentDate.stock_category_name = '';
                currentDate.stock_type = '';
                currentDate.stock_type_name = '';
                currentDate.stock_no = '';
                currentDate.plcw_auto_no = '';
                currentDate.material_code = '';
                currentDate.material_bg_code = '';
                currentDate.material_name = '';
                currentDate.material_model = '';
                currentDate.material_child_code = '';
                currentDate.material_child_dictcode = '';
                currentDate.material_child_name = '';
                currentDate.source_goods_code = '';
                currentDate.source_goods_name = '';
                this.$set(this.controlLiaoCang, this.adjustBtnIdx, currentDate);
            } else {
                let aObj = {};
                this.clickTiaozheng = data;
                aObj = Object.assign(this.controlLiaoCang[this.adjustBtnIdx], data);
                this.$set(this.controlLiaoCang, this.adjustBtnIdx, aObj);
                this.$set(this.producStatus, this.adjustBtnIdx, { status: '3' });
            }
        },

        // 分配比例调整
        async distributionRatio(item) {
            if (item.distribution_ratio === null) {return;}
            const result = await this.saveAllocationProportion(item.psrid, item.distribution_ratio);
            if (result) {
                this.$message({
                    message: '修改成功！',
                    type: 'success',
                });
            }
        },

        // 选中生产线
        async activeLineNameFun(data, index) {
            this.controlLiaoCang = [];
            this.activeLine = data;
            this.activeLineNo = data.no;
            this.activeLineName = data.name;
            this.activeLineIndex = index;
            await this.mixstationlinestock();
            await this.getProductsilo();

        },
        // 整体发送
        preservation() {
            if (this.scene !== 'view' ) {
                let siloNmuber;
                if (Object.keys(this.industrySiloData).length !== 0) {
                    siloNmuber = this.industrySiloData.industry_silo_number;
                } else {
                    siloNmuber = '-';
                }
                const titleObject = {
                    industry_silo_number: siloNmuber,
                    mixstation_code: this.activeStationCode,
                    mixstation_name: this.activeLineName,
                    line_code: this.activeLineNo,
                    line_name: this.activeLineName,
                    scbt: this.activeLine.mixing_platform,
                    silo_count: this.controlLiaoCang.length,
                    version: this.industrySiloData.version,
                    remarks: '',
                };
                const tableArray = [];
                this.controlLiaoCang.forEach(item => {
                    const tableObject = {
                        source_goods_code: item.source_goods_code,
                        source_goods_name: item.source_goods_name,
                        industry_silo_number: siloNmuber,
                        plcw_auto_no: item.plcw_auto_no || '',
                        version: item.version,
                        pre_version: '前一配置关系版本',
                        effect_time: item.created,
                        stock_code: item.stock_code,
                        stock_name: item.stock_name,
                        stock_alias: item.stock_alias,
                        stock_category: item.stock_category,
                        stock_category_name: item.stock_category_name,
                        stock_category_dictcode: item.stock_category_dictcode,
                        stock_type: item.stock_type,
                        stock_type_name: item.stock_type_name,
                        stock_no: item.stock_no,
                        plcw: item.plcw,
                        plcwname: item.plcwname,
                        material_code: item.material_code,
                        material_name: item.material_name,
                        material_model: item.material_model,
                        material_child_name: item.material_child_name,
                        material_child_code: item.material_child_code,
                        material_child_dictcode: item.material_child_dictcode,
                        material_bg_code: item.material_bg_code,
                    };
                    tableArray.push(tableObject);
                });
                if (Object.keys(this.industrySiloData).length !== 0) {
                    this.productsiloModify(titleObject, tableArray);
                } else {
                    this.productsiloAdd(titleObject, tableArray);
                }
            }
        },
        // 顶部tab切换
        handleClick(tab) {
            this.tabIndex = tab.index;
            this.activeStationCode = this.siteData[this.tabIndex].station_id;
            this.controlLiaoCang = [];
            setTimeout(() => {
                this.getLineList();
                this.activeLineIndex = 0;
            }, 200);
        },
        // 整体发送
        productsiloSend() {
            this.$axios
                .put(`/interfaceApi/production/productsilo/send/${this.industrySiloData.industry_silo_number}`)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 修改工控料仓关系
        async productsiloModify(title, table) {
            await this.$axios
                .post('/interfaceApi/production/productsilo/modify', {
                    industrySiloInput: title,
                    siloRelationInput: table,
                })
                .then(res => {
                    this.baocunSunccess = res;
                    this.productsiloSend();
                    this.mixstationlinestock();
                    this.getProductsilo();
                    this.$message.success('保存成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加工控料仓关系
        async productsiloAdd(title, table) {
            await this.$axios
                .post('/interfaceApi/production/productsilo/add', {
                    industrySiloInput: title,
                    siloRelationInput: table,
                })
                .then(res => {
                    this.baocunSunccess = res;
                    this.mixstationlinestock();
                    this.getProductsilo();
                    this.$message.success('保存成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // erp关系获取
        async getProductsilo() {
            await this.$axios
                .get(`/interfaceApi/production/productsilo/productsilo/${this.activeStationCode}/${this.activeLineNo}`)
                .then(res => {
                    this.industrySiloData = res.industrySilo || {};
                    const data = res.siloRelation || [];
                    this.controlLiaoCang = [];
                    this.controlLiaoCangOne.map(item => {
                        let arr = [];
                        arr = data.filter(v => item.plcw === v.plcw);
                        if (arr.length > 0) {
                            const json = { ...arr[0], ...item };
                            this.controlLiaoCang.push(json);
                        } else {
                            this.controlLiaoCang.push(item);
                        }
                    });
                    this.setProducStatus();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 工控生产线料仓列表
        async mixstationlinestock() {
            await this.$axios
                .get(`/interfaceApi/production/mixstationlinestock/stocks/${this.activeStationCode}/${this.activeLineNo}`)
                .then(res => {
                    this.controlLiaoCangOne = res;
                    this.setProducStatus();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产线信息
        async getLineList() {
            this.mixstationlineData = [];
            this.activeLine = [];
            await this.$axios
                .get(`/interfaceApi/BaseInfo/stationmanger/get_line_list?stationId=${this.activeStationCode}`)
                .then(res => {
                    if (res.length > 0) {
                        this.mixstationlineData = res;
                        if (this.scene === 'view') {
                            this.mixstationlineData.forEach((item, index) => {
                                if (item.no === this.activeLineNo) {
                                    this.activeLine = res[index];
                                    this.activeLineNo = res[index].no;
                                    this.activeLineName = res[index].name;
                                    this.mixstationlinestock();
                                    this.getProductsilo();
                                }
                            });
                        } else {
                            this.activeLine = res[0];
                            this.activeLineNo = res[0].no;
                            this.activeLineName = res[0].name;
                            this.mixstationlinestock();
                            this.getProductsilo();
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取场站信息
        async gitStationList() {
            const Uid = this.$takeTokenParameters('Uid');
            await this.$axios
                .get(`/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=${Uid}&roleCode=J05-05`)
                .then(res => {
                    if (res.length > 0) {
                        // 当前站点默认站点
                        this.siteData = res;
                        this.activeStationCode = res[0].station_id;
                        this.getLineList();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 保存分配比例
        saveAllocationProportion(psrid, distribution_ratio) {
            // eslint-disable-next-line max-len
            return this.$axios.$put(`/interfaceApi/production/productsilo/update/silo_ratio?psrid=${psrid}&distribution_ratio=${distribution_ratio}`, { defEx: true });
        },
    },
};

</script>
<style lang='stylus'>
.status-btn
    width: 2rem !important
    height .35rem !important
    font-size: .16rem !important
    margin-bottom .01rem !important
    margin-top 0 !important
    border-radius 0 !important
.confirm
    border 1px solid #FE7903 !important
    color #FE7903 !important
.confirm-ing
    border-radius 0 !important
    border 1px solid #C2C2C2 !important
    color #02AAC7 !important
.to-be-sent-locking
    border-radius 0 !important
    // border 1px solid #FE7903 !important
    // color #FFFFFF !important
    border 1px solid #C2C2C2 !important
    color #333 !important
.to-be-sent
    border-radius 0 !important
    border 1px solid #C2C2C2 !important
    color: #5588f1 !important
button
    cursor:pointer;
// 表格颜色设置
.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
.flex-box
    display flex
.center-erp-config-box
    width 100%
    display: flex;
    flex-direction: column;
    overflow: hidden;
.content-tab
    width 100%
    height 100%
    overflow-y auto
.zhanwei
    width .1rem !important
    background none !important
    border-top none !important
    border-bottom none !important
    border-left 1px solid #C1C1C3 !important
    border-right 1px solid #C1C1C3 !important
.table-table-box
    min-width 100%
    border none
    th,td
        height 0.4rem
    tr:last-child
        td
            border-bottom 1px solid #C1C1C3
    .table-tr-one
        height .4rem
        th
            font-size .18rem
            border 1px solid #C1C1C3
            border-right none
            &:first-child
                color #022782
                background #EEF0F6
                border-right 1px solid #C1C1C3
            &:last-child
                background #fff
                border none !important
.table-tr-two
    th
        border-bottom 1px solid #C1C1C3
        border-left 1px solid #C1C1C3
    .blue-bg-color
        background #F6F8FE
        color #022782
        width 2.2rem
        height .4rem
        font-size .18rem
        border-right 1px solid #C1C1C3
    .origin-bg-color
        background#FCF8F5
        color #823802
        width 2.2rem
        height .4rem
        font-size .18rem
        border-right 1px solid #C1C1C3
.current-color
    background #E2EAF3 !important
.table-tr-content
    td:first-child
            border-left 1px solid #C1C1C3
    td
        height .4rem
        font-size .16rem
        text-align center
        border-right 1px solid #C1C1C3
        border-top 1px solid #D6D6D8
        button
            width .6rem
            height .26rem
            border-radius .04rem
            color #FE7903
            border-color #FE7903
            background none
        .el-input-number
            line-height 0.36rem
            border 1px solid #DCDFE6
            border-radius 2px
            .el-input-number__decrease,
            .el-input-number__increase
                top 0
                height 0.36rem
                line-height 0.36rem
                display none
            .el-input-number__decrease
                left 0
            .el-input-number__increase
                right 0
            .el-input__inner
                border none
                padding 0 0.1rem
    .gongkong-content
        color #333333
        border-right 1px solid #C1C1C3
    .erp-content
        color #823802
        background #FFFEFF
        .please-select
            color #979EA7
        b
            display inline-block
            width: 0.6rem;
            height: 0.26rem;
            border-radius: 2px;
            border 1px solid #fe7903
            color: #fe7903;
            border-color: #fe7903;
            background: none;
            font-weight normal
            cursor pointer
        .xuanze
            color #5588F1
            border-color #5588F1
.operation-list
    width 2rem
    height .8rem
    text-align center
    line-height .8rem
    background #5588F1
    margin-left .22rem
    color #fff
    font-size .2rem
    display inline-block
    cursor:pointer;
    button
        width 2rem
        height .3rem
        margin-top .1rem
        font-size .16rem
        border none
        border-radius 0px


// 分割线
.header-tabs
    width 100%
    background #fff
    padding .2rem
    .el-tabs__item.is-active
        color #D6000F
    .el-tabs__nav div
        color #333333
        font-size .18rem
    .el-tabs__active-bar
        background #D6000F
    .el-tabs__nav-wrap::after
        height 1px
    .el-tabs__active-bar
        bottom: 1px;
.product-code-box
    .active-color
        background #409EFF
        border-color #409EFF
        color #fff
// 详细信息
.config-content-box
    width 100%
    margin-top .2rem
    background #FFFFFF
    padding 0 .2rem .2rem
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
.config-content-title
    display flex
    height .53rem
    align-items center
    div
        display flex
        color #333333
        font-size .18rem
        width 2.53rem
        span
            font-size .2rem
            display inline-block
            margin-right .05rem
        &:nth-child(1) span
            color #5588F1
        &:nth-child(2) span
            color #EB656F
        &:nth-child(3) span
            color #21BDCC
        &:nth-child(4) span
            color #FE7903
</style>